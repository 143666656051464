<template>
  <div>

    <v-card>
      <v-card-title>Listado de Muelles</v-card-title>
      <v-card-text>

        <botonera :botones="botones" alinear="right"
        @crear="dialog = !dialog"
        @cargar="upload = !upload"
        @eliminar="deleteAll"
        ></botonera>


        
      </v-card-text>
    </v-card>

    <datatable :cabeceras="muellesListHeaders" :items="muellesListBody" titulo="Listado de Muelles" label="Escriba Para buscar Muelle" icono="list" color_icono="azul"
    :tachar="true"
    :acciones="['editar','eliminar','mostrar','ocultar']"
    @editar="editMuelle($event)"
    @eliminar="deleteMuelle($event)"
    @mostrar="mostrar($event)"
    @ocultar="ocultar($event)">
    </datatable>

    <!-- <v-data-table 
        :headers="muellesListHeaders"
        :items="muellesListBody"
        class="elevation-1"
        no-data-text="No hay muelles"
        :search="search"
    >
      <template v-slot:[`item.nombre`]="{item}">
        <p v-if="item.mostrar == 1">{{ item.nombre }}</p>
        <p v-else class="text-decoration-line-through">{{ item.nombre }}</p>
      </template>
      <template v-slot:[`item.actions`]="{item}">
        <v-tooltip left v-if="item.mostrar == 0">
          <template v-slot:activator="{ on }">
            <v-icon
              medium
              class="mr-2"
              @click="mostrar(item)"
              v-on="on"
            >
              mdi-eye
            </v-icon>
          </template>
          <span>Mostrar Muelle</span>
        </v-tooltip>
        <v-tooltip left v-else>
          <template v-slot:activator="{ on }">
            <v-icon
              medium
              class="mr-2"
              @click="ocultar(item)"
              v-on="on"
            >
              mdi-eye-off
            </v-icon>
          </template>
          <span>Ocultar Muelle</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon
              medium
              class="mr-2"
              @click="editMuelle(item)"
              v-on="on"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>Editar Muelle</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon
              medium
              @click="deleteMuelle(item)"
              v-on="on"
            >
            mdi-delete
            </v-icon>
          </template>
          <span>Eliminar Muelle</span>
        </v-tooltip>
      </template>
    </v-data-table> -->

    <!-- Modal Nueva Muelle -->
    <v-dialog
      v-model="dialog"
      width="800px"
      @click:outside = "closeModal"
    >
      <v-card>
        <v-card-title class="blue darken-1">{{ title }}</v-card-title>
        <v-card-text>
          <v-row class="mx-2">
            <v-col
              class="align-center justify-space-between"
              cols="6"
            >
              <v-text-field
                prepend-icon="mdi-barcode"
                placeholder="Nombre"
                v-model="muelle.nombre"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="closeModal"
          >Cerrar</v-btn>
          <v-btn
            color="primary"
            @click="saveMuelle"
          >Grabar</v-btn>
        </v-card-actions>
              
      </v-card>
    </v-dialog>
    <!-- / Modal -->

    <!-- Modal upload -->
    <v-dialog
      v-model="upload"
      width="600px"
    >
      <v-card>
        <v-card-title class="green darken-1">
          Subir listado de muelles desde excel
        </v-card-title>
        <v-card-text>
          <v-row class="mx-2">
            <v-file-input v-model="file" accept=".xls, .xlsx" label="Archivo Excel"></v-file-input>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="upload = false"
          >Cancelar</v-btn>
          <v-btn
            color="primary"
            @click="uploadMuelles"
          >Subir</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- / Modal -->
    
    <v-snackbar
      v-model="snackbar"
      :timeout="5000"
      :color="color"
    >
      {{ validation_message }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>

  </div>
</template>
<script>
  import {mapState, mapMutations} from 'vuex'

  export default {
    data: () => ({
      title: 'Nuevo Muelle',
      color: 'green',
      snackbar: false,
      errors: [],
      validation_message: '',
      success: null,
      search: null,
      file: null,
      dialog: false,
      upload: false,
      muelleIndex: -1,
      muelle: {
        id: 0,
        nombre: '',
      },
      muellesListHeaders: [
        {
          text: 'Id',
          align: 'center',
          sortable: true,
          value: 'id',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Nombre',
          align: 'center',
          sortable: true,
          value: 'nombre',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Acciones',
          align: 'center',
          sortable: false,
          value: 'actions',
          class: "blue-grey darken-4 white-text"
        },
      ],
      muellesListBody: [],
      botones:[
        {boton:'crear',tooltip:'Nuevo Muelle'},
        {boton:'cargar',tooltip:'Carga Masiva'},
        {boton:'eliminar',tooltip:'Borrar Todo'}
      ]
    }),
    mounted() {
      this.loadMuelles();
    },
    methods:{
      async loadMuelles(){
        this.mostrarLoading('Cargando listado de muelles...')
        let url = `${this.base_url}muelles`;
        await this.axios.get(url, this.headers).then((response)=>{
          this.muellesListBody = response.data;
          this.ocultarLoading()
        }).catch((error)=>{
            console.log(error);
            this.ocultarLoading()
        });
      },
      async saveMuelle(){
        // validation
        this.errors = [];
        if(!this.muelle.nombre){
          this.errors.push("Debe indicar nombre");
        }else if(this.muelle.nombre.length > 20){
          this.errors.push("Tamaño máximo nombre: 20 caracteres");
        }
        if(this.errors.length > 0){
          this.validation_message = this.errors.join();
          this.showSnackBar(true);
          return;
        }
        let url = `${this.base_url}muelles`;
        let muelleData = {
          'nombre': this.muelle.nombre,
        };
        if(this.muelle.id > 0){
          url = url + "/" + this.muelle.id;
          await this.axios.put(url, muelleData, this.headers).then((response)=>{
            let index = this.muellesListBody.map(muelle => {
              return muelle.id;
            }).indexOf(this.muelle.id);
            this.muellesListBody.splice(index, 1, response.data);
            this.showSnackBar(false);
          }).catch((error)=>{
            this.validation_message = error;
            this.showSnackBar(true);
          });
        }else{
          await this.axios.post(url, muelleData, this.headers).then((response)=>{
            this.muellesListBody.push(response.data);
            this.showSnackBar(false);
          }).catch((error)=>{
            this.errors = error.response.data;
            this.validation_message = this.errors.join();
            this.showSnackBar(true);
          });
        }
        this.cleanForm();
      },
      editMuelle(item) {
        this.muelleIndex = this.muellesListBody.indexOf(item);
        this.muelle = Object.assign({}, item);
        this.dialog = true;
        this.title = "Editar Muelle";
      },
      async deleteMuelle(item) {
        this.$confirm('Desea borrar este muelle?').then(res => {
          if(res){
            let url = `${this.base_url}muelles/${item.id}`;
            this.axios.delete(url, this.headers).then((response)=>{
              console.log(response);
              this.loadMuelles();
              this.showSnackBar(false);
            }).catch((error)=>{
              this.validation_message = error;
              this.showSnackBar(true);
            });
          }
        });
      },
      showSnackBar(error){
        if(error){
          this.color = 'red';
        }else{
          this.color = 'green';
          this.validation_message = "Ejecutado exitosamente!";
        }
        this.snackbar = true;
      },
      closeModal(){
        this.cleanForm();
        this.dialog = false;
        this.error = null;
        this.title = "Nuevo Muelle";
      },
      cleanForm(){
        this.muelle = {
          id: 0,
          nombre: '',
        };
        this.dialog = false;
      },
      async uploadMuelles(){
        this.headers.headers['Content-Type'] = "multipart/form-data";
        let url = `${this.base_url}uploads/muelles`;
        let formData = new FormData();
        formData.append("excel", this.file);
        let response = null;
        try{
          response = await this.axios.post(url, formData, this.headers);
          this.file = null;
          this.upload = false;
          this.loadMuelles();
          this.showSnackBar(false);
        }catch(error){
          response = error.response;
          console.log(response);
          let msg = response.data.data;
          this.validation_message = response.status + ': ' + response.data.message + '. '+ msg;
          this.showSnackBar(true);
        }
      },
      async deleteAll(){
        this.$confirm('Desea borrar todos los datos?').then(res => {
          if(res){
            let url = `${this.base_url}truncar/muelles`;
            this.axios.get(url, this.headers).then((response)=>{
              console.log(response);
              this.loadMuelles();
              this.showSnackBar(false);
            }).catch((error)=>{
              this.validation_message = error;
              this.showSnackBar(true);
            });
          }
        });
      },
      async ocultar(item) {
        this.$confirm('Desea ocultar este muelle?').then(res => {
          if(res){
            let url = `${this.base_url}muelles/ocultar/${item.id}`;
            this.axios.get(url, this.headers).then((response)=>{
              console.log(response);
              this.showSnackBar(false);
              this.loadMuelles();
            }).catch((error)=>{
              this.validation_message = error;
              this.showSnackBar(true);
            });
          }
        });
      },
      async mostrar(item) {
        this.$confirm('Desea mostrar este muelle?').then(res => {
          if(res){
            let url = `${this.base_url}muelles/mostrar/${item.id}`;
            this.axios.get(url, this.headers).then((response)=>{
              console.log(response);
              this.showSnackBar(false);
              this.loadMuelles();
            }).catch((error)=>{
              this.validation_message = error;
              this.showSnackBar(true);
            });
          }
        });
      },
      ...mapMutations(['actualizarBaseUrl', 'mostrarLoading', 'ocultarLoading']),
    },
    computed:{
      ...mapState(['loading', 'base_url', 'headers']),
      
    },
    components:{
      
    }
  }
</script>